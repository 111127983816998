.react-calendar {
    /* 전체 calender */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    border: none;
}

.react-calendar__navigation {
    /* navigation 전체 */
    display: flex;
    align-items: center;
    min-width: 160px;
    height: 32px;
    margin-bottom: 8px;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
    /* 왼족 한 개 넘기기 */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 100%;
    font-size: 26px;
}
.react-calendar__navigation__arrow.react-calendar__navigation__prev-button:disabled {
    /* 왼족 한 개 넘기기 */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 100%;
    font-size: 26px;
    color: #bbb;
}
.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 100%;
    font-size: 26px;
}

.react-calendar__navigation button {
    /* navigation buttons */
    min-width: 0;
    min-height: 0;
}

.react-calendar__viewContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 32px);
}

.react-calendar__navigation__label {
    /* 가운데 날짜 */
    height: 24px;
    font-size: 18px;
    font-weight: 600;
}

.react-calendar button:disabled {
    background-color: white;
}

.react-calendar__month-view__week {
    height: calc(100% / 6);
}

.react-calendar__month-view__weekdays__weekday {
    /* 요일 전체 묶음 */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    max-width: 40px;
    margin-bottom: 2px;
    color: #333;
    font-size: 14px;
    font-weight: 400;
}

abbr[title] {
    text-decoration: none;
}

.react-calendar__month-view {
    height: 100%;
}

.react-calendar__month-view > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.react-calendar__month-view__days {
    height: calc(100% - 44px);
    width: 100%;
}

.react-calendar__tile.react-calendar__month-view__days__day {
    /* 일반 모든 날짜(지난 것 제외) */
    color: black;
    height: 40px;
    max-width: 40px;
    font-size: 14px;
    font-weight: 400;
}
.react-calendar__tile.react-calendar__month-view__days__day:hover {
    /* 일반 모든 날짜(지난 것 제외) */
    background-color: white;
    border: 1px solid #989ba2;
    border-radius: 12px;
}
.react-calendar__tile.react-calendar__month-view__days__day:focus {
    /* 일반 모든 날짜(지난 것 제외) */
    background-color: white;
    border: 1px solid #333438;
    border-radius: 12px;
}

.react-calendar__tile.react-calendar__month-view__days__day:disabled {
    /* disabled */
    color: #aaa;
    pointer-events: none;
}

.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
    /* 주말 날짜 */
    color: #f00 !important;
}

.react-calendar__month-view__days__day--weekend:disabled {
    /* 주말 날짜 disabled */
    opacity: 0.4;
}

.react-calendar__tile--active {
    background-color: #264c97 !important;
    border-radius: 12px;
    color: white !important;
}

.react-calendar__tile--active:hover {
    background-color: #20438b !important;
    border: none !important;
    color: white !important;
}

.react-calendar__tile--now {
    /* 금일 날짜 표시 */
    border-radius: 12px;
    background-color: #e8f1fe;
    color: black;
}

.react-calendar__tile--now:hover {
    /* 금일 날짜 표시 */
    background-color: #e8f1fe !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: transparent;
}

.react-calendar__year-view,
.react-calendar__year-view__months,
.react-calendar__decade-view {
    height: 100%;
}

.react-calendar__tile.react-calendar__year-view__months__month,
.react-calendar__tile.react-calendar__decade-view__years__year,
.react-calendar__tile.react-calendar__century-view__decades__decade {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 73px;
}

.react-calendar__tile--hasActive {
    background-color: #264c97;
    color: white;
    border-radius: 12px;
}

.react-calendar__tile--hasActive:enabled:hover {
    background-color: #20438b;
    color: white;
    /* border-radius: 12px; */
}

.react-calendar-parent-class {
    height: 100%;
    width: 100%;
}

.homeCalendar .react-calendar__tile.react-calendar__month-view__days__day {
    height: 30px;
}

.homeCalendar .react-calendar__tile {
    padding: 0;
}

.homeCalendar .react-calendar__tile.react-calendar__month-view__days__day:disabled {
    color: inherit;
    opacity: 1;
}

.homeCalendar .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend:disabled {
    color: red;
    opacity: 1;
}

.homeCalendar button.react-calendar__navigation__label {
    cursor: default !important;
}

.homeCalendar .react-calendar__tile--now {
    background-color: #264c97;
    color: white;
}
